import React from 'react'

import '../../styles/index.sass'
import { H3, H4 } from '../../components/typography'
import Link from '../../components/link'
import { Section } from '../../components/section'
import Header from '../../components/header'
import Footer from '../../components/footer'
import SEO from '../../components/seo'

const CodeOfConduct = () => (
    <>
        <SEO title="Code of Conduct" />
        <Header linkTo="/2019" />
        <Section title="Code of Conduct">
            <p>
                This Code of Conduct is based on the{' '}
                <Link to="https://numfocus.org/code-of-conduct">NumFOCUS Code of Coduct</Link> and
                released under a{' '}
                <Link to="http://creativecommons.org/licenses/by-nc-sa/3.0/">
                    creative commons license
                </Link>
                .
            </p>

            <H3>Standards for Behavior</H3>

            <p>
                All communication should be appropriate for a professional audience including people
                of many different backgrounds. Please always be kind and courteous. There’s never a
                need to be mean or rude or disrespectful. Thank you for helping make this a
                welcoming, friendly community for all. We strive to:
            </p>

            <ul>
                <li>
                    <p>
                        <strong>Be empathetic, welcoming, friendly, and patient.</strong> We
                        remember that spaCy IRL 2019 is crafted by human beings who deserve to be
                        treated with kindness and empathy. We work together to resolve conflict and
                        assume good intentions. We may all experience some frustration from time to
                        time, but we do not allow frustration to turn into a personal attack. A
                        community where people feel uncomfortable or threatened is not a productive
                        one.
                    </p>
                </li>

                <li>
                    <p>
                        <strong>Be collaborative.</strong> Our work depends on the participation of
                        many people, and in turn others depend on our work. Open source communities
                        depend on effective and friendly collaboration to achieve their goals.
                    </p>
                </li>

                <li>
                    <p>
                        <strong>Be inquisitive.</strong> Nobody knows everything! Asking questions
                        early avoids many problems later, so we encourage questions, although we may
                        direct them to the appropriate forum. We will try hard to be responsive and
                        helpful.
                    </p>
                </li>

                <li>
                    <p>
                        <strong>Be careful in the words that we choose.</strong> We are careful and
                        respectful in our communication and we take responsibility for our own
                        speech. Be kind to others. Do not insult or put down other members of the
                        community.
                    </p>
                </li>
            </ul>

            <H3>Unacceptable Behavior</H3>

            <p>
                We are committed to making participation in this community a harassment-free
                experience. We will not accept harassment or other exclusionary behaviors, such as:
            </p>

            <ul>
                <li>The use of sexualized language or imagery</li>
                <li>
                    Excessive profanity (please avoid curse words; people differ greatly in their
                    sensitivity to swearing)
                </li>
                <li>Posting sexually explicit or violent material</li>
                <li>Violent or intimidating threats or language directed against another person</li>
                <li>
                    Inappropriate physical contact and/or unwelcome sexual attention or sexual
                    comments
                </li>
                <li>Sexist, racist, or otherwise discriminatory jokes and language</li>
                <li>Trolling or insulting and derogatory comments</li>
                <li>
                    Written or verbal comments which have the effect of excluding people on the
                    basis of membership in a specific group, including level of experience, gender,
                    gender identity and expression, sexual orientation, disability, neurotype,
                    personal appearance, body size, race, ethnicity, age, religion, or nationality
                </li>
                <li>Public or private harassment</li>
                <li>
                    Sharing private content, such as emails sent privately or non-publicly, or
                    direct message history, without the sender’s consent
                </li>
                <li>
                    Continuing to initiate interaction (such as photography, recording, messaging,
                    or conversation) with someone after being asked to stop
                </li>
                <li>
                    Sustained disruption of talks, events, or communications, such as heckling of a
                    speaker
                </li>
                <li>
                    Publishing (or threatening to post) other people’s personally identifying
                    information (“doxing”), such as physical or electronic addresses, without
                    explicit permission
                </li>
                <li>Other unethical or unprofessional conduct</li>
                <li>Advocating for, or encouraging, any of the above behaviors</li>
            </ul>

            <H3>Reporting Guidelines</H3>

            <p>
                If you believe someone is violating the code of conduct, please report this in a
                timely manner. Code of conduct violations reduce the value of the community for
                everyone. The team at spaCy IRL takes reports of misconduct very seriously and is
                committed to preserving and maintaining the welcoming nature of our community.{' '}
                <strong>All reports will be kept confidential.</strong>
            </p>

            <p>
                In some cases we may determine that a public statement will need to be made. If
                that’s the case, the identities of all involved parties and reporters will remain
                confidential unless those individuals instruct us otherwise.
            </p>

            <p>
                All complaints will be reviewed and investigated and will result in a response that
                is deemed necessary and appropriate to the circumstances. The spaCy IRL team commits
                to maintaining confidentiality with regard to the reporter of an incident. For
                possibly unintentional breaches of the code of conduct, you may want to respond to
                the person and point out this code of conduct (either in public or in private,
                whatever is most appropriate). If you would prefer not to do that, please report the
                issue to the spaCy IRL team directly.
            </p>

            <p>
                Take care of each other. Alert the spaCy IRL team if you notice a dangerous
                situation, someone in distress, or violations of this code of conduct, even if they
                seem inconsequential.
            </p>

            <H4>How to submit a report</H4>

            <p>
                If you are being harassed, notice that someone else is being harassed, or have any
                other concerns, please contact a member of conference staff. You can also email the
                organizers, in confidence, at{' '}
                <Link to="mailto:contact@explosion.ai">contact@explosion.ai</Link>.
            </p>
        </Section>
        <Footer />
    </>
)

export default CodeOfConduct
